<template>
  <v-expansion-panels
    v-model="expanded"
    accordion
    v-bind="$attrs"
  >
    <v-expansion-panel v-if="showName">
      <v-card-title>
        {{ drug.name[$i18n.locale] || drug.name.en }}
        <v-spacer />
        <arkscore-gauge
          v-if="showArkScoreGauge"
          :key="'arkscore-gauge-' + drug.score_adverse_reaction"
          :value="drug.score_adverse_reaction"
          :size="36"
          right
        />
      </v-card-title>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-card-text class="pt-0">
        <v-row>
          <v-col
            v-if="showArkScoreBar"
            cols="12"
            class="mb-n8"
          >
            <arkscore-card
              :name="$t('arkscore.adverse_reaction')"
              :score="drug.score_adverse_reaction"
              :bar-height="9"
              class="mt-3 pb-2"
              full-width
              blurb="The likelihood of the drug to cause adverse effects to the patient."
              flat
            />
          </v-col>
          <v-col
            v-if="drug.brand_names"
            cols="12"
          >
            <div class="text-overline text-no-wrap">
              {{ $t('drug_info.brand_names') }}
            </div>
            {{ drug.brand_names }}
          </v-col>
          <v-col
            v-if="drug.delivery_methods?.length > 0"
            class="mt-n2"
            cols="12"
          >
            <div class="text-overline">
              {{ $tc('drug_info.delivery_methods', drug.delivery_methods?.length) }}
            </div>
            <v-chip
              v-for="method in drug.delivery_methods"
              :key="method"
              :color="stringColor($delivery_methods.find(m => m.value == method).text)"
              class="me-1 mb-1 pa-4"
              small
              label
            >
              <v-icon
                left
                small
              >
                {{ $delivery_methods.find(m => m.value == method).icon }}
              </v-icon>
              {{ $t($delivery_methods.find(m => m.value == method).text.toLowerCase()) | capitalize }}
            </v-chip>
          </v-col>
        </v-row>
        <v-row
          justify="space-between"
          class="mt-n2"
        >
          <v-col
            v-if="!standardDosage && !drug.standard_dosages"
            md="auto"
          >
            <div class="text-overline">
              {{ $t('drug_info.dosing_req') }}
            </div>
            <v-icon small>
              fal fa-fw fa-{{ drug.hepatic_dosing ? 'check' : 'times' }}
            </v-icon>
            {{ $t('drug_info.hepatic_dosing') }}
            <br>
            <v-icon small>
              fal fa-fw fa-{{ drug.renal_dosing ? 'check' : 'times' }}
            </v-icon>
            {{ $t('drug_info.renal_dosing') }}
          </v-col>
          <v-col
            v-if="drug.drug_interactions || drug.live_drug_interactions"
            md="auto"
          >
            <div class="text-overline text-no-wrap">
              {{ $t('drug_info.drug_interact') }}
            </div>
            <ul v-if="drug.live_drug_interactions">
              <li
                v-for="interaction, index in drug.live_drug_interactions.filter((v, i, a) => a.findIndex(x => x.description === v.description) === i)"
                :key="index"
              >
                {{ interaction.description }}
              </li>
            </ul>
            <span v-else>
              {{ drug.drug_interactions }}
            </span>
          </v-col>
          <v-col
            v-if="drug.common_side_effects"
            md="auto"
          >
            <div class="text-overline text-no-wrap">
              {{ $t('drug_info.side_effects') }}
            </div>
            {{ drug.common_side_effects }}
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="text-overline text-no-wrap">
              {{ $t('Antimicrobial Resistance') }}
            </div>
            <template v-if="drug.resistance && resistancesTested && resistancesTested.some(r => drug.resistance.map(dr => dr.name.en).includes(r))">
              {{ $t('drug_info.resistance_tested') }}
            </template>
            <template v-else>
              {{ $t('drug_info.resistance_not_tested') }}
            </template>
          </v-col>
        </v-row>
        <v-row
          v-if="standardDosage"
          class="mt-n2"
        >
          <v-col
            v-if="standardDosage.adjustments"
            md="12"
          >
            <div class="text-overline text-no-wrap">
              {{ $t('drug_info.dosage_adjustments') }}
            </div>
            <div class="text-caption mb-3 mt-n2">
              Based on standard dosage of {{ standardDosage.dosage }}
            </div>
            <ul class="ml-n2">
              <li
                v-for="adjustment, index in sorted_reference_adjustments"
                :key="index"
              >
                <b>
                  {{ adjustment.parameter }}
                  {{ adjustment.modifier != 'range' ? adjustment.modifier : null }}
                  {{ adjustment.modifier != 'range' ? adjustment.value : adjustment.value.join('-') }}
                </b>
                {{ adjustment.dosage }}
              </li>
            </ul>
          </v-col>
        </v-row>
        <v-row
          class="mb-n6"
        >
          <v-col>
            <div
              v-if="drug.standard_dosages || loading.standard_dosages"
              class="text-overline"
              style="width: 100%"
            >
              {{ $t('drug_info.standard_dosing') }}
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-skeleton-loader
        :loading="loading.standard_dosages"
        type="list-item-two-line"
      >
        <v-responsive />
      </v-skeleton-loader>
    </v-expansion-panel>
    <v-expansion-panel
      v-for="dosing, index in drug.standard_dosages"
      :key="index"
      :value="`standard_dosage_${index}`"
      class="elevation-0"
    >
      <v-expansion-panel-header>
        {{ dosing.dosage }}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-list
          class="ml-n2"
          dense
        >
          <v-list-item
            v-for="adjustment, index in adjustmentSort(dosing.adjustments)"
            :key="index"
            class="px-0"
          >
            <v-list-item-avatar
              min-width="106"
              class="my-0"
            >
              <v-chip
                small
                outlined
                label
                class="font-weight-medium"
              >
                {{ adjustment.parameter }}
                {{ adjustment.modifier != 'range' ? adjustment.modifier : null }}
                {{ adjustment.modifier != 'range' ? adjustment.value : adjustment.value.join('-') }}
              </v-chip>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-subtitle class="text-wrap">
                {{ adjustment.dosage }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
export default {
  components: {
    ArkscoreCard: () => import('@/components/arkscore/ArkscoreCard.vue'),
    ArkscoreGauge: () => import('@/components/arkscore/ArkscoreGauge.vue'),
  },
  props: {
    drug: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    standardDosage: {
      type: Object,
      default: null,
    },
    patient: {
      type: Object,
      default: () => ({}),
    },
    resistancesTested: {
      type: Array,
      default: () => ([]),
    },
    showName: Boolean,
    showArkScoreGauge: Boolean,
    showArkScoreBar: Boolean,
  },
  data () {
    return {
      loading: {
        standard_dosages: false,
      },
      expanded: 2,
      adjustment_order: [
        "Hepatic",
        "Renal",
        "CrCl",
        "GFR",
        "eGFR",
        "PD",
        "HD",
        "CRRT",
        "CVVH",
        "CVVHD",
        "CVVHDF",
        "Hybrid HD",
        "PIRRT",
      ],
    }
  },
  computed: {
    sorted_reference_adjustments () {
      return this.adjustmentSort(this.adjustmentFilter([ ...this.standardDosage.adjustments]))
    },
  },
  watch: {
    drug: {
      handler: function () {
        if (this.drug.id && !this.drug.standard_dosages) {
          this.fetchStandardDosages()
        }
      },
      deep: true,
    },
  },
  methods: {
    fetchStandardDosages() {
      this.loading.standard_dosages = true
      const promise = this.axios.get('report/drugs/' + this.drug.id)
        return promise.then((response) => {
          this.$set(this.drug, 'standard_dosages', response.data.standard_dosages)
          this.loading.standard_dosages = false
        })
    },
    adjustmentSort(adjustments) {
      let sorted_adjustments = this.adjustmentFilter([ ...adjustments ])
      return sorted_adjustments.sort((a, b) => this.adjustment_order.indexOf(a.parameter) - this.adjustment_order.indexOf(b.parameter))
    },
    adjustmentFilter(adjustments) {
      if (this.patient && (this.patient.crcl || this.patient.gfr)) {
        adjustments = adjustments.filter(adjustment => {
          if (this.patient.gfr && this.patient.gfr > 0) {
            if (adjustment.parameter == 'Renal') {
              return true
            } else if (adjustment.parameter != 'GFR' && adjustment.parameter != 'eGFR' && !this.patient.crcl) {
              return false
            } else {
                let modifier = adjustment.modifier
                if (modifier == '≥') {
                  modifier = '>='
                } else if (modifier == '≤') {
                  modifier = '<='
                }
              if (adjustment.modifier == 'range') {
                return this.patient.gfr >= adjustment.value[0] && this.patient.gfr <= adjustment.value[1]
              } else {
                return eval(`${this.patient.gfr} ${modifier} ${adjustment.value}`)
              }
            }
          }

          if (this.patient.crcl && this.patient.crcl > 0) {
            if (adjustment.parameter == 'Renal') {
              return true
            } else if (adjustment.parameter != 'CrCl') {
              return false
            } else {
              if (adjustment.modifier == 'range') {
                return this.patient.crcl >= adjustment.value[0] && this.patient.crcl <= adjustment.value[1]
              } else {
                let modifier = adjustment.modifier
                if (modifier == '≥') {
                  modifier = '>='
                } else if (modifier == '≤') {
                  modifier = '<='
                }
                return eval(`${this.patient.crcl} ${modifier} ${adjustment.value}`)
              }
            }
          }

          return true
        })
      }
      return adjustments
    },
  },
}
</script>
